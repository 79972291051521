import 'slick-carousel/slick/slick';
import 'jquery-confirm/dist/jquery-confirm.min.js';
import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';

window.InfiniteAjaxScroll = InfiniteAjaxScroll;

import {getProperty, number_format, showDialog, updateCart, updateHtmlCart, addBarcodeScanner} from './modules/functions.js'
import 'inputmask';
import 'inputmask.phone/dist/inputmask.phone/inputmask.phone.extensions.js';
import 'inputmask.phone/dist/inputmask.phone/phone-codes/phone-nl.js';
import 'url-polyfill';

try {
    window.$ = window.jQuery = require('jquery');

    // Bootstrap elements
    require('bootstrap');

    window.getProperty = getProperty;
    window.number_format = number_format;
    window.showDialog = showDialog;
    window.updateCart = updateCart;
    window.updateHtmlCart = updateHtmlCart;
    window.addBarcodeScanner = addBarcodeScanner;
} catch (e) {}

if (!Math.trunc) {
    Math.trunc = function (v) {
        return v < 0 ? Math.ceil(v) : Math.floor(v);
    };
}

const $document = $(document);

$document.ready(() => {

    // Add CSRF token
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    });

    // Adjust search url
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search.slice(1));

    if (params.has('keyword')) {
        var paramValue = params.get('keyword');
        params.delete('keyword');

        window.history.pushState(null, null, `${params}/zoeken/${paramValue}`);
    }

    // Website alert
    const franchiseAlert = localStorage.getItem('franchiseAlert');

    if (!franchiseAlert) {
        $('[data-id="franchise-alert"]').removeClass('d-none');
    }

    $document.on('click', '[data-id="franchise-alert"] .close', (e) => {
        localStorage.setItem('franchiseAlert', '1');
    });


    /**
     * Ajax form loader
     */
    $(document).on('click', '[data-trigger="ajax-form"]', function(e) {
        var url = $(this).attr('data-remote');

        var $button = $(this);
        var $spinner = $('<i class="fas fa-spinner fa-spin mr-3"></i>');
        $button.prepend($spinner);

        $.ajax({
            url: url,
            method: 'get',
            dataType: 'json',
            success: function(json) {
                $button.find('.fa-spinner').remove();

                showDialog(json);
            },
            error: function(xhr) {
                $button.find('.fa-spinner').remove();
            }
        })
    });

    /**
     * Delivery date setter.
     */
    $(document).on('change, click', '[data-trigger="select-delivery-date"]', function(e)
    {
        e.preventDefault();

        var date = $(this).attr('data-delivery-date') != undefined ? $(this).attr('data-delivery-date') : $(this).val();
        var dialog = $(this).attr('data-dialog') != undefined ? true : false;
        var reload = $(this).attr('data-reload') != undefined ? true : false;

        $.ajax({
            url: '/instellingen/leverdatum/' + date,
            method: 'POST',
            responseType: 'JSON',
            success: function(json) {
                if (dialog) {
                    showDialog(json);
                }

                if (reload) {
                    window.location.reload();
                }
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    });

    /**
     * Delivery spot setter.
     */
    $(document).on('change, click', '[data-trigger="select-delivery-spot"]', function(e)
    {
        e.preventDefault();

        var spot_id = $(this).attr('data-spot-id') != undefined ? $(this).attr('data-spot-id') : $(this).val();
        var dialog = $(this).attr('data-dialog') != undefined ? true : false;
        var reload = $(this).attr('data-reload') != undefined ? true : false;

        $.ajax({
            url: '/instellingen/leverblok/' + spot_id,
            method: 'POST',
            responseType: 'JSON',
            success: function(json) {
                if (dialog) {
                    showDialog(json);
                }

                if (reload) {
                    window.location.reload();
                }
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    });

    /**
     * Deliver type setter.
     */
    $(document).on('change, click', '[data-trigger="select-deliver-type"]', function(e)
    {
        e.preventDefault();

        var type = $(this).attr('data-delivery-type') != undefined ? $(this).attr('data-delivery-type') : $(this).val();
        var dialog = $(this).attr('data-dialog') != undefined ? true : false;
        var reload = $(this).attr('data-reload') != undefined ? true : false;

        $.ajax({
            url: '/instellingen/levertype/' + type,
            method: 'POST',
            responseType: 'JSON',
            success: function(json) {

                if (dialog) {
                    showDialog(json);
                }

                if (reload) {
                    window.location.reload();
                }
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    });

    /**
     * Payment provider setter.
     */
    $(document).on('change, click, blur', '[data-trigger="select-payment-provider"]', function(e)
    {
        e.preventDefault();

        var paymentProvider = $(this).attr('data-payment-provider') != undefined ? $(this).attr('data-payment-provider') : $(this).val();
        var dialog = $(this).attr('data-dialog') != undefined ? true : false;
        var reload = $(this).attr('data-reload') != undefined ? true : false;

        $.ajax({
            url: '/instellingen/betaalmethode/' + paymentProvider,
            method: 'POST',
            responseType: 'JSON',
            success: function(json) {
                if (dialog) {
                    showDialog(json);
                }

                if (reload) {
                    window.location.reload();
                }
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    });

    $(document).on('click', '[data-trigger="checkout-address-form"]', function(e) {
        e.preventDefault();

        $.ajax({
            url: '/instellingen/leveradres',
            method: 'POST',
            responseType: 'JSON',
            success: function(json) {
                showDialog(json);
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    })

    /**
     * Shop setter.
     */
    $(document).on('click', '[data-trigger="select-shop"]', function(e)
    {
        e.preventDefault();

        var slug = $(this).attr('data-shop-slug');

        $.ajax({
            url: '/instellingen/winkel/' + slug,
            method: 'POST',
            responseType: 'JSON',
            success: function(json) {
                showDialog(json);

                window.location.reload();
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    });

    // Ajax form submitter
    $(document).on('submit', 'form[data-ajax-form]', function(e) {
        e.preventDefault();

        var $container = $(this).parents('.card-body');
        var $form = $container.find('.data-form');
        var $static = $container.find('.data-static');

        var method = $form.attr('method');
        var data = $form.serialize();
        var action = $form.attr('action');

        $.ajax({
            method: method,
            data: data,
            url: action,
            success: function(json) {
                showDialog(json);

                $form.addClass('d-none');
                $static.removeClass('d-none');

                if (json.result) {
                    $static.html(json.result);
                }
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    });

    $(document).on('click', '[data-trigger="toggle-form"]', function(e) {
        e.preventDefault();

        var $container = $(this).parents('.card-body');
        var $form = $container.find('.data-form');
        var $static = $container.find('.data-static');

        $form.toggleClass('d-none');
        $static.toggleClass('d-none');

        return false;
    });

    /**
     * Address form completer
     */
     var zipcodeXhr;
    $(document).on('change', 'form[data-address-form] [name="zipcode"], form[data-address-form] [name="zip_code"], form[data-address-form] [name="housenumber"], form[data-address-form] [name="number"]', function(e) {
        var $form = $(this).parents('form[data-address-form]');
        var $zipcodeInput = $form.find('[name="zipcode"]');
        var $housenumberInput = $form.find('[name="housenumber"]');
        var $spinner = $form.find('.fa-spinner');
        var $errorMsg = $form.find('[data-target="error-message"]');

        if ($zipcodeInput.length == 0) {
            $zipcodeInput = $form.find('[name="zip_code"]');
        }

        if ($housenumberInput.length == 0) {
            $housenumberInput = $form.find('[name="number"]');
        }

        var zipcode = $zipcodeInput.val();
        var housenumber = $housenumberInput.val();

        if (zipcode != '' && housenumber != '') {

            // Show loading spinner
            $spinner.removeClass('d-none');

            if (zipcodeXhr !== undefined) {
                zipcodeXhr.abort();
            }

            zipcodeXhr = $.ajax({
                url: '/postcode/' + zipcode + '/' + housenumber,
                dataType: 'json',
                success: function(json) {
                    // Hide loading spinner
                    $spinner.addClass('d-none');
                    $errorMsg.addClass('d-none');

                    $form.find('[name="street"]').val(json.zipcode.street);
                    $form.find('[name="city"]').val(json.zipcode.city);
                },
                error: function(xhr) {
                    if (xhr.statusText != 'abort') {
                        // Hide loading spinner
                        $spinner.addClass('d-none');

                        // Show error
                        console.error(xhr);

                        if (xhr.status == 404) {
                            $errorMsg.html('Adres onbekend').removeClass('d-none').addClass('text-danger');
                        }

                        else {
                            $errorMsg.addClass('d-none').removeClass('text-danger');
                        }

                        $form.find('[name="street"]').val('');
                        $form.find('[name="city"]').val('');
                    }
                }
            });
        }
    });

    /**
     * When a customer wants to close their account
     */
    $(document).on('click', '[data-close-customer-account]', function(e) {
        e.preventDefault();

        $.confirm({
            title: 'Ga je ons verlaten?',
            content: 'Weet je zeker dat je ons wilt verlaten? Je klant gegevens worden in dat geval uit het systeem gehaald.',
            cancelButton: 'Blijven',
            buttons: {
                formSubmit: {
                    text: 'Uitschrijven',
                    btnClass: 'btn-blue',
                    action: function () {
                        $.ajax({
                            url: '/mijn/klantprofiel-uitschrijven',
                            method: 'post',
                            success: function(json) {
                                showDialog(json);

                                window.location = '/';

                            },
                            error: function(xhr) {
                                var data = {
                                    dialog: {
                                        type: 'alert',
                                        title: 'Onbekende fout',
                                        message: 'Door een onbekende fout hebben we je klant profiel niet kunnen uitschreven. Neem a.u.b. contact op via info@ah-boodschappen.nl om alsnog uw klant profiel uit te schrijven.',
                                    }
                                };

                                showDialog(data);
                                console.error(xhr);
                            }
                        })
                    }
                },
                stayBtn: {
                    text: 'Blijven',
                    action: function () {
                        this.close();
                    }
                }
            },
        });
    });

    /**
     * Register form entity change behaviour
     */
    $(document).on('change', '[name="entity"]', function(e) {
        var value = $(this).val();

        switch(value) {
            case 'business':
                $('#register-company-data').removeClass('d-none');
            break;
            case 'private':
                $('#register-company-data').addClass('d-none');
            break;
        }
    });

    /**
     * Closest shop locator
     */
    $(document).on('click', '[data-action="locale-closest-shop"]', function(e) {
        e.preventDefault();

        var $form = $(this).parents('form:eq(0)');
        var zipcode = $form.find('[name="zipcode"]').val();
        var housenumber = $form.find('[name="housenumber"]').val();


        if (zipcode.trim() == '' && housenumber.trim() == '') {
            var data = {
                dialog: {
                    type: 'dialog',
                    title: 'Waar zit je?',
                    message: 'We hebben nog geen adres gegevens van je ontvangen. Geef deze op in je registratie formulier zodat we de dichtsbijzijnde Albert Heijn winkel voor je kunnen opzoeken.',
                }
            };

            showDialog(data);
        }

        else {
            $.ajax({
                url: '/winkel-in-de-buurt/' + zipcode + '/' + housenumber,
                method: 'POST',
                dataType: 'json',
                success: function(json) {
                    showDialog(json);

                    if (json.shop) {
                        $('[name="active_shop_id"]').val(json.shop.id);
                    }
                },
                error: function(xhr) {
                    var data = {
                        dialog: {
                            title: 'Onbekende fout',
                            message: 'Er is een onbekende fout opgetreden.',
                        }
                    };

                    showDialog(data);
                }
            })
        }


        return false;
    });

    $(document).on('submit', 'form.form-zipcode', function(e) {
        e.preventDefault();

        $.ajax({
            url: $(this).attr('action'),
            method: 'post',
            data: $(this).serialize(),
            success: function(json) {
                window.location.reload();
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    });

    $(document).on('click', '[data-trigger="clear-deliver-address"]', function(e) {
        e.preventDefault();

        $.ajax({
            url: '/instellingen/leveradres/legen',
            method: 'post',
            success: function(json) {
                window.location.reload();
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        return false;
    });


    $(document).on('change', '[data-trigger="set-packaging"]', function(e) {
        e.stopPropagation();
        var packaging = $('[name="packaging"]:checked').val();

        $.ajax({
            url: '/instellingen/verpakking/' + packaging,
            method: 'post',
            success: function(json) {
                updateHtmlCart(json);
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        // return false;
    });

    // Auto set the packaging when needed
    var $packagingTrigger = $('[data-trigger="set-packaging"]');
    if ($packagingTrigger.length == 1) {
        $packagingTrigger.prop('checked', true);
        $packagingTrigger.trigger('change');
    }

    $(document).on('change', '[data-trigger="set-purchase-stamps"]', function(e) {
        e.stopPropagation();

        var purchase_stamps = $('[name="purchase_stamps"]:checked').val();

        purchase_stamps = purchase_stamps == undefined ? 'false' : 'true';

        $.ajax({
            url: '/instellingen/koopzegels/' + purchase_stamps,
            method: 'post',
            success: function(json) {
                updateHtmlCart(json);
            },
            error: function(xhr) {
                var data = {
                    dialog: {
                        title: 'Onbekende fout',
                        message: 'Er is een onbekende fout opgetreden.',
                    }
                };

                showDialog(data);
            }
        });

        // return false;
    });



    if ($('#products-grid').length) {
        let ias = new InfiniteAjaxScroll('#products-grid', {
            item: '#products-grid > .col',
            next: '.page-link[rel="next"]',
            pagination: '.pagination'
        });
    }

    // Set filter toggle
    const $filters = $('.filters');
    $filters.on('click', '[data-toggle]', function () {
        var toggle = $(this).data('toggle');

        $content = $('[data-content="' + toggle + '"]');
        $oldText = $(this).text();
        $newText = $(this).attr('toggle-text');

        $(this)
            .attr('toggle-text', $oldText)
            .text($newText);

        if ($content.is('[hidden]')) {
            $content.removeAttr('hidden');
        } else {
            $content.attr('hidden', 'true');
        }
    });


    $(document).on('click', '[data-toggle-favorite]', function(e) {

        var $btn = $(this);
        var product_id = $btn.attr('data-product-id');
        var newStatus = $btn.hasClass('active') ? 0 : 1;
        var url = '/mijn/favoriet/' + product_id + '/' + newStatus;

        $btn.find('.fa').removeClass('fa-heart').addClass('fa-spinner fa-spin');

        $.ajax({
            url: url,
            method: 'POST',
            dataType: 'json',
            success: function(json) {
                $btn.find('.fa').addClass('fa-heart').removeClass('fa-spinner fa-spin');

                if (newStatus) {
                    $btn.removeClass('inactive');
                    $btn.addClass('active');
                }

                else {
                    $btn.addClass('inactive');
                    $btn.removeClass('active');
                }
            },
            error: function(xhr) {
                console.error(xhr);
            }
        })

    });

    // Set connections to html elements
    $(document).on('submit', 'form[data-action="cart-update"]', function(e)
    {
        e.preventDefault();

        var id = $(this).find('[name="id"]').val();
        var content_type = $(this).find('[name="content_type"]').val();
        var quantity = $(this).find('[name="quantity"]').val();

        updateCart(id, content_type, quantity);
    });

    $(document).on('change', 'form[data-action="cart-update"]', function(e)
    {
        $(this).submit();
    });

    $(document).on('click', '[data-action="increase-quantity"]', function(e)
    {
        var $form = $(this).parents('form[data-action="cart-update"]');
        var $buttonsSet = $form.parents('.buttons-set:eq(0)');
        var $quantity = $form.find('[name="quantity"]');
        var quantity = parseInt($quantity.val());

        quantity++;
        quantity = Math.max(0, quantity);

        $quantity.val(quantity).trigger('change');

        if (quantity <= 0) {
            $buttonsSet.removeClass('buttons-set-open');
        } else {
            $buttonsSet.addClass('buttons-set-open');
        }
    });

    $(document).on('click', '[data-action="decrease-quantity"]', function(e)
    {
        var $form = $(this).parents('form[data-action="cart-update"]');
        var $buttonsSet = $form.parents('.buttons-set:eq(0)');
        var $quantity = $form.find('[name="quantity"]');
        var quantity = parseInt($quantity.val());

        quantity--;
        quantity = Math.max(0, quantity);

        $quantity.val(quantity).trigger('change');

        if (quantity <= 0) {
            $buttonsSet.removeClass('buttons-set-open');
        } else {
            $buttonsSet.addClass('buttons-set-open');
        }
    });

    $(document).on('click', '[data-trigger="cart-clear"]', function(e) {
        e.preventDefault();

        $.confirm({
            title: 'Winkelmandje legen',
            content: 'Weet je zeker dat je je winkelmandje wilt legen? Dit kan niet ongedaan worden gemaakt.',
            buttons: {
                confirm: {
                    text: 'Winkelmandje leegmaken',
                    btnClass: 'btn-blue',
                    keys: ['enter', 'shift'],
                    action: function(){
                        $.ajax({
                            method: 'post',
                            url: '/winkelmandje/legen',
                            success: function(json) {
                                window.location.reload();
                            },
                            error: function(xhr) {
                                var data = {
                                    dialog: {
                                        title: 'Onbekende fout',
                                        message: 'Er is een onbekende fout opgetreden.',
                                    }
                                };

                                showDialog(data);
                            }
                        });
                    }
                },
                cancel: {
                    text: 'Annuleren',
                    btnClass: 'btn-outline-dark',
                    keys: ['esc'],
                    action: function() {

                    }
                }
            }
        });

        return false;
    });

    $(document).on('click', '[data-action="open-inline-cart"]', function(e) {
        e.preventDefault();

        $('#cart-inline-wrapper').removeClass('cart-inline-closed').addClass('cart-inline-open');
        $('body').css('overflow', 'hidden');

        return false;
    });

    $(document).on('click', '[data-action="close-inline-cart"]', function(e) {
        e.preventDefault();

        $('#cart-inline-wrapper').removeClass('cart-inline-open').addClass('cart-inline-closed');
        $('body').css('overflow', 'visible');

        return false;
    });

    $(document).on('click', '#cart-inline-wrapper', function(e) {
        if ($(e.target).attr('id') == 'cart-inline-wrapper') {
            $('#cart-inline-wrapper').removeClass('cart-inline-open').addClass('cart-inline-closed');
            $('body').css('overflow', 'visible');
        };
    });

    // Inputmasks
    $('.inputmask-phone').inputmask('phonenl');
    $('.inputmask-email').inputmask({alias: 'email'});
    $('.inputmask-zipcode').inputmask('9999 AA');
    $('.inputmask-housenumber').inputmask({
        // mask: '9',
        // greedy: false,
        // repeat: 10,
        regex: '[0-9\-]+',
        onKeyValidation: function (key, result) {
            if (!result) {
                var $input = $(this);
                var $form = $(this).parents('form:eq(0)');
                var $additional = $form.find('[name="housenumber_addition"]');

                var additional = $additional.val() + String.fromCharCode(key);

                $additional.val(additional);
                $additional.trigger('focus');
            }
        }
    });
    $('.inputmask-bonuscard').inputmask({
        mask: '9 999999 999999',
        autoUnmask: true,
        unmaskAsNumber: true,
    });
    $('.inputmask-iban').inputmask('AA99 AAAA 9999 9999 99');
    $('.inputmask-date').inputmask({alias: 'datetime', 'inputFormat': 'dd-mm-yyyy', 'placeholder': '_'});
    $('.inputmask-time').inputmask({alias: 'datetime', 'inputFormat': 'HH:MM', 'placeholder': '_'});

    $(document).on('submit', 'form', function(e) {
        var $form = $(this);
        var $inputMaskElements = $form.find('input').filter(function () {
            return this.className.match(/\binputmask\-/);
        });

        if ($inputMaskElements.length) {
            $.each($inputMaskElements, function() {
                // this.inputmask.unmask();
                // console.log('foo', this.inputmask);
            });
        }
    });
});

$('.slick-carousel').slick({
    dots: false,
    slidesToShow: 27,
    slidesToScroll: 1,
    infinite: false,
    swipeToSlide: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [{
        breakpoint: 426,
        settings: {
            slidesToShow: 8,
            slidesToScroll: 1
            }
        }]
});

