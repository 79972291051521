/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 *
 * Useful in a plugin or module that accepts a JSON array of objects, but
 * you want to let the user specify where to find various bits of data
 * inside of each custom object instead of forcing a standardized
 * property list.
 *
 * @param String nested A dot notation style parameter reference (ie "urls.small")
 * @param Object object (optional) The object to search
 *
 * @return the value of the property in question
 */

export function getProperty(propertyName, object) {
  var parts = propertyName.split( "." ),
    length = parts.length,
    i,
    property = object || this;

  for ( i = 0; i < length; i++ ) {
    if (property[parts[i]] != undefined) {
      property = property[parts[i]];
    }
  }

  return property;
}

export function number_format(number, decimals, decPoint, thousandsSep) { // eslint-disable-line camelcase
  //  discuss at: https://locutus.io/php/number_format/
  // original by: Jonas Raoni Soares Silva (https://www.jsfromhell.com)
  // improved by: Kevin van Zonneveld (https://kvz.io)
  // improved by: davook
  // improved by: Brett Zamir (https://brett-zamir.me)
  // improved by: Brett Zamir (https://brett-zamir.me)
  // improved by: Theriault (https://github.com/Theriault)
  // improved by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: Michael White (https://getsprink.com)
  // bugfixed by: Benjamin Lupton
  // bugfixed by: Allan Jensen (https://www.winternet.no)
  // bugfixed by: Howard Yeend
  // bugfixed by: Diogo Resende
  // bugfixed by: Rival
  // bugfixed by: Brett Zamir (https://brett-zamir.me)
  //  revised by: Jonas Raoni Soares Silva (https://www.jsfromhell.com)
  //  revised by: Luke Smith (https://lucassmith.name)
  //    input by: Kheang Hok Chin (https://www.distantia.ca/)
  //    input by: Jay Klehr
  //    input by: Amir Habibi (https://www.residence-mixte.com/)
  //    input by: Amirouche
  //   example 1: number_format(1234.56)
  //   returns 1: '1,235'
  //   example 2: number_format(1234.56, 2, ',', ' ')
  //   returns 2: '1 234,56'
  //   example 3: number_format(1234.5678, 2, '.', '')
  //   returns 3: '1234.57'
  //   example 4: number_format(67, 2, ',', '.')
  //   returns 4: '67,00'
  //   example 5: number_format(1000)
  //   returns 5: '1,000'
  //   example 6: number_format(67.311, 2)
  //   returns 6: '67.31'
  //   example 7: number_format(1000.55, 1)
  //   returns 7: '1,000.6'
  //   example 8: number_format(67000, 5, ',', '.')
  //   returns 8: '67.000,00000'
  //   example 9: number_format(0.9, 0)
  //   returns 9: '1'
  //  example 10: number_format('1.20', 2)
  //  returns 10: '1.20'
  //  example 11: number_format('1.20', 4)
  //  returns 11: '1.2000'
  //  example 12: number_format('1.2000', 3)
  //  returns 12: '1.200'
  //  example 13: number_format('1 000,50', 2, '.', ' ')
  //  returns 13: '100 050.00'
  //  example 14: number_format(1e-8, 8, '.', '')
  //  returns 14: '0.00000001'

  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  var n = !isFinite(+number) ? 0 : +number
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  var s = ''

  var toFixedFix = function (n, prec) {
    if (('' + n).indexOf('e') === -1) {
      return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
    } else {
      var arr = ('' + n).split('e')
      var sig = ''
      if (+arr[1] + prec > 0) {
        sig = '+'
      }
      return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
    }
  }

  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }

  return s.join(dec)
}

/**
 * Dialog helpers. Shows a dialog. Used for xhr responses.
 *
 * @param  object data
 * @return void
 */
export function showDialog(data) {

    if (data.dialog !== undefined) {
        var title = data.dialog.title;
        var content = data.dialog.message;
        var type = data.dialog.type;
        var color = data.dialog.color;
        var columnClass = data.dialog.width != undefined ? data.dialog.width : 'col-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3';

        switch(type) {
            case 'dialog':
                $.dialog({
                    title: title,
                    content: content,
                    type: color != undefined ? color : 'blue',
                    columnClass: columnClass,
                });
            break;
            case 'alert':
                $.alert({
                    title: title,
                    content: content,
                    type: color != undefined ? color : 'red',
                    columnClass: columnClass,
                });
            break;
            case 'form':
                if (content !== undefined) {
                    content += data.dialog.form;
                } else {
                    content = data.dialog.form;
                }

                var dialog = $.dialog({
                    title: title,
                    content: content,
                    type: color != undefined ? color : 'blue',
                    columnClass: columnClass,
                    closeAnimation: 'none',
                    onClose: function() {
                        $(document).off('submit', '.jconfirm form');
                    }
                });

                // Enable close form
                $(document).on('click', '[data-trigger="close-form"]', function(e) {
                    dialog.close();
                });

                // Ajaxify the form
                $(document).on('submit', '.jconfirm form', function(e) {
                    e.preventDefault();

                    var $form = $(this);
                    var $submitBtn = $form.find('[type="submit"]');
                    var $spinner = $('<i class="fas fa-spinner fa-spin mr-3"></i>');
                    $submitBtn.prepend($spinner);

                    $.ajax({
                        url: $form.attr('action'),
                        method: $form.attr('method'),
                        responseType: 'JSON',
                        data: $form.serialize(),
                        success: function(json) {
                            // Reload the page if needed
                            if ($form.attr('data-reload') !== undefined) {
                                window.location.reload();
                            }

                            // Show a dialog if needed
                            if (json.dialog !== undefined) {
                                // Close the current dialog
                                dialog.close();

                                // Open the new dialog
                                showDialog(json);
                            }

                            // If we have a result, return the result in the target div
                            if (json.result !== undefined) {
                                $(json.result.target).html(json.result.content);
                            }

                            $submitBtn.find('.fa-spinner').remove();
                        },
                        error: function(xhr, json, b, c) {
                            // In case of validation errors
                            if (xhr.status == 422) {
                                // Remove previous validation messages
                                $form.find('.is-invalid').removeClass('is-invalid');
                                $form.find('.invalid-feedback').remove();

                                // Add new validation messages
                                if (xhr.responseJSON.errors !== undefined) {
                                    $.each(xhr.responseJSON.errors, function(key, errors) {
                                        var $input = $form.find('[name="' + key + '"]');
                                        var $errorMessage = '<span class="invalid-feedback" role="alert">' + errors.join(' ') + '</span>';

                                        console.log('input', $input);

                                        // $input.parent().siblings().find('.invalid-feedback').remove();
                                        $input.addClass('is-invalid');
                                        $input.after($errorMessage);

                                    });
                                }
                            }

                            // In all other cases show a general error
                            else {
                                // Close the current dialog
                                dialog.close();

                                var data = {
                                    dialog: {
                                        title: 'Onbekende fout',
                                        message: 'Er is een onbekende fout opgetreden.',
                                    }
                                };

                                showDialog(data);

                            }

                            $submitBtn.find('.fa-spinner').remove();
                        }
                    });

                    return false;
                });
            break;
        }


    }
}

// Save our xhr history
var xhrHistory = {};
export function updateCart(id, content_type, quantity)
{
    var data = {
        'id': id,
        'content_type': content_type,
        'quantity': quantity
    };

    var index = content_type + id;

    if (xhrHistory[index] != undefined) {
        xhrHistory[index].abort();
    }

    // xhrHistory.abort();
    xhrHistory[index] = $.ajax({
        url: '/winkelmandje/toevoegen',
        method: 'post',
        data: data,
        success: function(json) {
            // Clear our xhr request from history
            xhrHistory[index] = undefined;

            // Show dialog(s) when needed
            showDialog(json);

            updateHtmlCart(json);
        },
        error: function(xhr, exception) {
            switch (xhr.status) {
                case 0:
                case 404:
                case 500:
                    var data = {
                        dialog: {
                            title: 'Onbekende fout',
                            message: 'Er is een onbekende fout opgetreden.',
                        }
                    };

                    showDialog(data);
                break;
            }
        }
    });

}

export function updateHtmlCart(json)
{
    // Set correct display
    if (json.items.length == 0) {
        $('.cart-content').addClass('d-none');
        $('.cart-empty').removeClass('d-none');
    }

    else {
        $('.cart-content').removeClass('d-none');
        $('.cart-empty').addClass('d-none');
    }

    // Set all of our existing cart targets
    $.each($('[data-cart-target]'), function() {
        var $target = $(this);
        var property = $target.attr('data-cart-target');
        var value = getProperty(property, json);

        // Check to see if we have a valid value (string, int, float, bool)
        if (value != undefined && (typeof value != "object")) {

            var format = $target.attr('data-cart-format');

            // Reformat value if needed
            switch(format) {
                case 'price':
                    if (parseFloat(value) == 0) {
                      value = 'Gratis';
                    }

                    else {
                      value = '&euro;&nbsp;' + number_format(value, 2, ',', '.');
                    }
                break;
            }

            // Update element
            if ($target.is(':input')) {
                $target.val(value);
            }

            else {
                $target.html(value);
            }
        }
    });

    // Update cart items
    $.each($('tbody[data-cart-items]'), function() {

        var $table = $(this).parents('table:eq(0)');
        var device = $table.attr('data-device');
        var $itemContainer = $(this);

        // Remove old items
        $.each($itemContainer.find('[data-cart-item-id]'), function() {
            if (json.items[$(this).attr('data-cart-item-id')] == undefined) {
                $(this).fadeOut(300, function() { $(this).remove(); });
            }
        });

        // Add new items
        $.each(json.items, function() {
            var item = this;


            if ($itemContainer.find('[data-cart-item-id="' + item.id + '"]').length == 0) {
                var bonus = '';

                $.each(item.modifiers, function() {
                    var modifier = this;

                    if (modifier.subtotal_incl_vat < 0) {
                        bonus += ' <span class="badge badge-bonus">' + modifier.name + '</span>';
                    }

                });

                if (device == 'desktop') {
                  var $cartItemRow = '<tr data-cart-item-id="' + item.id + '">' +
                      '<td class="col-name pl-3">' +
                          '<a href="" target="_blank">' +
                              item.name +
                          '</a>' +
                          bonus +
                      '</td>' +
                      '<td class="text-center col-quantity">' +
                          '<span class="buttons-set ' + (item.quantity > 0 ? ' buttons-set-open' : '') + (bonus != '' ? ' buttons-set-bonus' : '') + '">' +
                              '<form data-action="cart-update">' +
                                  '<input type="hidden" name="id" value="' + item.buyable_id + '">' +
                                  '<input type="hidden" name="content_type" value="Workbench\\Product\\Documents\\ProductDocument">' +
                                  '<button type="button" class="btn-quantity" data-action="decrease-quantity">-</button> ' +
                                  '<input class="input-quantity" type="text" name="quantity" value="' + item.quantity + '" data-cart-target="items.' + item.id + '.quantity"> ' +
                                  '<button type="button" class="btn-quantity" data-action="increase-quantity">+</button> ' +
                              '</form>' +
                          '</span>' +
                      '</td>' +
                      '<td class="col-price text-right" data-cart-target="items.' + item.id + '.price_incl_vat" data-cart-format="price">' +
                          '&euro;&nbsp;' + number_format(item.price_incl_vat, 2, ',', '.') +
                      '</td>' +
                      '<td class="col-price text-right" data-cart-target="items.' + item.id + '.subtotal_incl_vat" data-cart-format="price">' +
                          '&euro;&nbsp;' + number_format(item.subtotal_incl_vat, 2, ',', '.') +
                      '</td>' +
                  '</tr>';
                }

                else if (device == 'mobile') {
                  var $cartItemRow = '<tr data-cart-item-id="' + item.id + '">' +
                      '<td class="col-name pl-3">' +
                          '<a href="" target="_blank">' +
                              item.name +
                          '</a>' +
                          bonus +
                          '<span class="buttons-set ' + (item.quantity > 0 ? ' buttons-set-open' : '') + (bonus != '' ? ' buttons-set-bonus' : '') + '">' +
                              '<form data-action="cart-update">' +
                                  '<input type="hidden" name="id" value="' + item.buyable_id + '">' +
                                  '<input type="hidden" name="content_type" value="Workbench\\Product\\Documents\\ProductDocument">' +
                                  '<button type="button" class="btn-quantity" data-action="decrease-quantity">-</button> ' +
                                  '<input class="input-quantity" type="text" name="quantity" value="' + item.quantity + '" data-cart-target="items.' + item.id + '.quantity"> ' +
                                  '<button type="button" class="btn-quantity" data-action="increase-quantity">+</button> ' +
                              '</form>' +
                          '</span>' +
                      '</td>' +
                      '<td class="col-price text-right" data-cart-target="items.' + item.id + '.price_incl_vat" data-cart-format="price">' +
                          '&euro;&nbsp;' + number_format(item.price_incl_vat, 2, ',', '.') +
                      '</td>' +
                  '</tr>';
                }

                if ($itemContainer.find('tr.row-total').length) {
                    $itemContainer.find('tr.row-total').before($cartItemRow);
                }

                else {
                    $itemContainer.append($cartItemRow);
                }
            }

        });
    });

    // Update misc modifiers
    $.each($('tbody[data-misc-modifiers]'), function() {

        var $itemContainer = $(this);

        var columnCount = $(this).parents('table:eq(0)').find('thead th').length;

        if (columnCount == 0) {
            $(this).find('> tr:eq(0) > th, > tr:eq(0) > td').each(function(col) {
                var $col = $(this);

                if ($col.attr('colspan') !== undefined) {
                    columnCount += parseInt($col.attr('colspan'));
                }

                else {
                    columnCount++;
                }
            });
        }

        // Add new items
        $.each(json.miscModifiers, function() {
            var modifier = this;

            if ($itemContainer.find('[data-modifier-id="' + modifier.id + '"]').length == 0 && modifier.subtotal_incl_vat > 0) {
                var $cartItemRow = '<tr data-modifier-id="' + modifier.id + '">' +
                    '<td class="pl-3 col-name" data-cart-target="miscModifiers.' + modifier.id + '.description" ' + (columnCount > 2 ? 'colspan="'+(columnCount -1)+'"' : '') + '>' +
                        modifier.description +
                    '</td>' +
                    '<td class="text-right col-price" data-cart-target="miscModifiers.' + modifier.id + '.subtotal_incl_vat" data-cart-format="price">' +
                        '&euro;&nbsp;'+ number_format(modifier.subtotal_incl_vat, 2, ',', '.') +
                    '</td>' +
                '</tr>';

                if ($itemContainer.find('tr.row-total').length) {
                    $itemContainer.find('tr.row-total').before($cartItemRow);
                }

                else {
                    $itemContainer.append($cartItemRow);
                }
            }
        });

        // Remove old items
        $.each($itemContainer.find('tr[data-modifier-id]'), function() {
            var $row = $(this);
            if (json.miscModifiers[$row.attr('data-modifier-id')] == undefined || json.miscModifiers[$row.attr('data-modifier-id')].subtotal_incl_vat <= 0) {
                $row.remove();
            }
        });

        if ($itemContainer.find('[data-modifier-id]').length == 0) {
            $itemContainer.addClass('d-none');
        } else {
            $itemContainer.removeClass('d-none');
        }
    });

    // Update cart modifiers
    $.each($('tbody[data-bonus-modifiers]'), function() {

        var $itemContainer = $(this);

        var columnCount = $(this).parents('table:eq(0)').find('thead th').length;

        // Add new items
        $.each(json.bonusModifiers, function() {
            var modifier = this;

            if ($itemContainer.find('[data-modifier-id="' + modifier.id + '"]').length == 0 && modifier.subtotal_incl_vat < 0) {
                var $cartItemRow = '<tr data-modifier-id="' + modifier.id + '">' +
                    '<td class="pl-3 col-name" data-cart-target="bonusModifiers.' + modifier.id + '.description" ' + (columnCount > 2 ? 'colspan="'+(columnCount -1)+'"' : '') + '>' +
                        modifier.description +
                    '</td>' +
                    '<td class="text-right col-price" data-cart-target="bonusModifiers.' + modifier.id + '.subtotal_incl_vat" data-cart-format="price">' +
                        '&euro;&nbsp;'+ number_format(modifier.subtotal_incl_vat, 2, ',', '.') +
                    '</td>' +
                '</tr>';

                if ($itemContainer.find('tr.row-total').length) {
                    $itemContainer.find('tr.row-total').before($cartItemRow);
                }

                else {
                    $itemContainer.append($cartItemRow);
                }
            }
        });

        // Remove old items
        $.each($itemContainer.find('tr[data-modifier-id]'), function() {
            var $row = $(this);
            if (json.bonusModifiers[$row.attr('data-modifier-id')] == undefined || json.bonusModifiers[$row.attr('data-modifier-id')].subtotal_incl_vat >= 0) {
                $row.remove();
            }
        });

        if ($itemContainer.find('[data-modifier-id]').length == 0) {
            $itemContainer.addClass('d-none');
        } else {
            $itemContainer.removeClass('d-none');
        }
    });

}

export function addBarcodeScanner(callback) {
    var barcodeInMemory = '';
    var timeFromKeypress = 0;
    var timer;

    $(document).off('keypress');
    $(document).on('keypress', function(e)
    {
      if (!$(e.target).is('input:text')) {
        timeFromKeypress = 0;
        barcodeInMemory += String.fromCharCode(e.keyCode);

        // $('#waitforresults').show();
        // $('#barcode-status').html('Ontvangen: (' + $('#scannedBarcode').html().trim() + ')');
      }
    });

    if (typeof timer === 'undefined') {
      resetBarcodeCounter();
    }

    function resetBarcodeCounter() {
      // $('#time').html(timeFromKeypress);
      timeFromKeypress += 100;

      // If we haven't pressed a key for a second and we have previous keys present
      if (timeFromKeypress > 1000 && barcodeInMemory != '') {
        // $('#history').append($('<li />').html($('#scannedBarcode').html()));
        // $('#scannedBarcode').html('');
        var barcode = barcodeInMemory;

        callback(barcode);

        barcodeInMemory = '';

        // $('#history').append('<li>'+barcode+'</li>');
        // $('#scannedBarcode').html('');
        // $('#barcode-status').html('Wachten op scanner');

      }

      timer = setTimeout(function() {
        resetBarcodeCounter();
      }, 100);
    }
  }
